@import './_variables.less';
@import '~antd/dist/antd.less';
@import './ant-alert.less';
@import './ant-btn.less';
@import './ant-collapse.less';
@import './ant-descriptions.less';
@import './ant-form-item.less';
@import './ant-input.less';
@import './ant-layout.less';
@import './ant-menu-item.less';
@import './ant-menu.less';
@import './ant-modal.less';
@import './ant-picker-calendar.less';
@import './ant-picker.less';
@import './ant-popover.less';
@import './ant-radio.less';
@import './ant-segmented.less';
@import './ant-spin.less';
@import './ant-steps.less';
@import './ant-table.less';
@import './ant-tabs.less';
@import './ant-tag.less';
@import './ant-tree.less';
@import './ant-upload.less';
@import './ant-switch.less';
// fix anticon vertical-align
.anticon {
	vertical-align: 0.125rem;
}
