.ant-segmented {
	@apply h-10 overflow-hidden rounded-md bg-white p-0;

	.ant-segmented-group {
		height: inherit;
	}

	.ant-segmented-item-label {
		@apply min-h-max;
	}

	.ant-segmented-item {
		@apply flex items-center justify-center;
	}
	.ant-segmented-item-selected {
		@apply bg-secondary text-white;
	}
	.ant-segmented-thumb {
		@apply bg-secondary text-white;
	}

	&:not(.ant-segmented-disabled):hover,
	&:not(.ant-segmented-disabled):focus {
		@apply bg-background-secondary;
	}
}
