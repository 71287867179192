@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .border-gradient {
    border-image-slice: 1;
    border-image-source: var(--color-gradient);
  }
}

@layer components {
  html,
  body {
    @apply font-normal !important;
    @apply bg-background-primary;
  }
  #__next {
    @apply h-full bg-background-primary;
  }
}
