.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
	@apply mx-4;
}

.ant-steps-label-vertical .ant-steps-item-tail {
	@apply ~"ml-[63px] top-9 px-[43px]";
}

.ant-steps-item-tail::after {
	@apply h-1;
}

.ant-steps-item.ant-steps-item-active,
.ant-steps-item.ant-steps-item-finish {
	.ant-steps-icon {
		@apply border-secondary;
	}
}

.ant-steps-item.ant-steps-item-wait {
	.ant-steps-icon {
		@apply text-[#6F6F6F];
	}
}

.ant-steps-item-subtitle {
	@apply text-[#4F4F4F];
}
