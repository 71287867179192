.ant-picker-calendar {
	.ant-radio-button-wrapper {
		@apply text-text-primary;

		&:hover {
			@apply text-text-primary;
		}

		&.ant-radio-button-wrapper-checked {
			@apply text-secondary;
		}
	}
	.ant-radio-button-wrapper:first-child {
		@apply rounded-tl-md rounded-bl-md;
	}
	.ant-radio-button-wrapper:last-child {
		@apply rounded-tr-md rounded-br-md;
	}
	.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
		@apply border-secondary;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		@apply border-secondary;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
		@apply shadow-none;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
		@apply bg-secondary;
	}
}
