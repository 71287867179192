.ant-input {
	@apply rounded-md;
	line-height: 30px;

	&:hover {
		@apply border-secondary;
	}

	&:focus {
		@apply border-secondary;
		@apply shadow-none;
		@apply border-r;
		@apply outline-0;
	}
}

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
	@apply rounded-l-md;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
	@apply py-0;
	@apply rounded-r-md;
}

.ant-input-search-button {
	@apply h-10;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
	@apply border-secondary;
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
	@apply border-secondary;
	@apply border-l-0;
	@apply transition-none;
}

.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
	@apply bg-white;
	@apply border-l-0;
	border-color: #d9d9d9;
}

.ant-input-search {
	.ant-input {
		&:focus,
		&:hover {
			@apply border-r-0;
			@apply transition-none;
		}
	}
}

.ant-select:not(.ant-select-customize-input):not(.ant-pagination-options-size-changer):not(.ant-select-disabled)
	.ant-select-selector {
	@apply relative;
	@apply bg-white;
	@apply border;
	@apply rounded-md;
	@apply transition-all;
}

.ant-select-single:not(.ant-pagination-options-size-changer) {
	.ant-select-selector .ant-select-selection-item,
	.ant-select-selector .ant-select-selection-placeholder {
		@apply leading-10;
	}

	&:not(.ant-select-customize-input) .ant-select-selector {
		@apply h-10;
	}
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	@apply border-secondary;
	@apply shadow-none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	@apply border-secondary;
}

.ant-select-single:not(.ant-select-customize-input):not(.ant-pagination-options-size-changer)
	.ant-select-selector
	.ant-select-selection-search-input {
	@apply h-full;
}

.ant-input-affix-wrapper {
	@apply rounded-md;
}

.ant-input-number {
	@apply block h-10 w-full overflow-hidden rounded-md;

	.ant-input-number-input-wrap,
	.ant-input-number-input {
		@apply h-full;
	}
}

.ant-input-number:hover {
	@apply border-secondary;
	@apply border-r;
}

.ant-input-number:focus,
.ant-input-number-focused {
	@apply border-secondary;
	@apply shadow-none;
	@apply border-r;
	@apply outline-0;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	@apply border-secondary;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
	@apply border-secondary;
	@apply shadow-none;
	@apply outline-0;
}

.ant-select-show-search {
	.ant-input-search > .ant-input-group {
		.ant-input-affix-wrapper,
		.ant-input-affix-wrapper-focused {
			@apply border-r-0;
			@apply rounded-tl-md rounded-bl-md;

			&:hover,
			&:focus {
				@apply border-r-0;
			}
		}
	}

	&:hover,
	&:focus {
		.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
			@apply border-secondary;
		}
	}

	.ant-input-affix-wrapper-focused + .ant-input-group-addon {
		.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
			@apply border-secondary;
		}
	}
}

.ant-input[disabled] {
	@apply text-black;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
	.ant-select-selector {
		@apply text-black;
	}
}

.ant-input-number-disabled {
	@apply text-black;
}

.ant-input-number-handler-wrap {
	display: none;
}

.ant-picker-input {
	& > input[disabled] {
		@apply text-black;
	}
}

.ant-radio-disabled {
	& + span {
		@apply text-black;
	}
}

.ant-checkbox-wrapper-disabled {
	span {
		@apply text-black;
	}
}

.ant-select.ant-select-multiple > .ant-select-selector {
	min-height: 40px;
}
