.ant-drawer-body {
	.ant-menu-vertical:not(.ant-menu-dark) > .ant-menu-item:hover,
	.ant-menu-vertical:not(.ant-menu-dark) > .ant-menu-submenu:hover,
	.ant-menu-vertical:not(.ant-menu-dark) > .ant-menu-item-active,
	.ant-menu-vertical:not(.ant-menu-dark) > .ant-menu-submenu-active,
	.ant-menu-vertical:not(.ant-menu-dark) > .ant-menu-item-open,
	.ant-menu-vertical:not(.ant-menu-dark) > .ant-menu-submenu-open,
	.ant-menu-vertical:not(.ant-menu-dark) > .ant-menu-item-selected,
	.ant-menu-vertical:not(.ant-menu-dark) > .ant-menu-submenu-selected {
		@apply text-primary;
		@apply bg-background-secondary;
	}

	.ant-menu-item {
		@apply ant-btn-text;
		@apply ant-btn-round;
		@apply items-center;
		@apply text-text-secondary;

		&:not(:last-child) {
			@apply mr-1;
		}

		display: flex;
		border-radius: 32px;

		> svg {
			margin-top: auto;
			margin-bottom: auto;
		}
	}
}
