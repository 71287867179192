.ant-menu {
	&.ant-menu-horizontal {
		height: 32px;

		.ant-menu-item {
			display: flex;
			color: white !important;
			align-items: center;
			@apply px-4 !important;
			@apply mr-2;
			@apply ant-btn-round;

			&:hover,
			&.ant-menu-item-selected,
			&.ant-menu-submenu-selected {
				background-color: var(--primary-color);
				@apply text-text-primary;
			}

			&::after {
				border-bottom: none !important;
			}
		}

		.ant-menu-submenu {
			.ant-menu-submenu-title {
				display: flex;
				color: white !important;
				align-items: center;
				@apply ant-btn-round;

				&:hover,
				&.ant-menu-item-selected,
				&.ant-menu-submenu-selected {
					background-color: var(--primary-color);
					@apply text-text-primary;
				}

				&::after {
					border-bottom: none !important;
				}
			}

			&.ant-menu-submenu-horizontal {
				padding: 0;

				&::after {
					border-bottom: none;
				}
			}

			&.ant-menu-submenu-selected {
				.ant-menu-submenu-title {
					background-color: var(--primary-color);
					@apply text-text-primary;
				}
			}
		}
	}

	&.ant-menu-inline,
	&.ant-menu-vertical {
		border-right: none;

		.ant-menu-item {
			align-items: center;
			display: flex;

			&.ant-menu-item-selected {
				color: var(--color-primary);
				background-color: var(--color-background-secondary);

				&::after {
					display: none;
				}
			}
		}

		.ant-menu-submenu-vertical .ant-menu-submenu-title {
			display: flex;
			align-items: center;
		}
	}
}
