.ant-collapse.ant-collapse-ghost {
  & .ant-collapse-header {
    @apply border !rounded-md;
    & .ant-collapse-header-text {
      @apply whitespace-nowrap text-ellipsis overflow-hidden;
    }
  }
  & .ant-collapse-content-box {
    @apply !p-0;
  }
}