.ant-radio-inner::after {
	@apply bg-secondary;
}
.ant-radio-checked .ant-radio-inner {
	@apply border-secondary;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	@apply border-secondary;
}

.ant-radio-input:focus + .ant-radio-inner {
	box-shadow: 0 0 0 3px rgba(24, 255, 97, 0.12);
}
