.ant-tabs-ink-bar {
	@apply bg-secondary;
}

.ant-tabs-tab.ant-tabs-tab-active {
	.ant-tabs-tab-btn {
		@apply text-secondary;
	}
}
.ant-tabs-tab:hover {
	@apply text-primary;
}

.ant-tabs-content {
	@apply h-full;
}
.ant-tabs-tabpane-active {
	@apply h-full;
}
