label.ant-form-item-required {
	@apply flex flex-row-reverse justify-end;
	&:before {
		@apply ml-1;
	}
}
.ant-form-item-label {
	& > label {
		@apply h-10 font-semibold;
	}
}
