.ant-picker {
	@apply h-10 rounded-md;
	&:hover {
		@apply border-secondary;
	}
	&.ant-picker-focused {
		@apply border-secondary;
		@apply shadow-none;
	}
	.ant-picker-active-bar {
		@apply bg-secondary;
	}
}
.ant-picker-cell-in-view.ant-picker-cell-today {
	.ant-picker-cell-inner::before {
		@apply border-secondary;
	}
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	@apply bg-secondary;
}
.ant-form-item-has-error {
	.ant-picker {
		@apply border-[#ff4d4f];
	}
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
	@apply !visible;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
	@apply !visible;
}

.ant-picker-panels > *:last-child {
	@apply hidden;
}

.ant-picker-panel-container,
.ant-picker-footer {
	width: 280px !important;
}

.ant-picker-footer-extra > div {
	@apply !flex-wrap;
}
