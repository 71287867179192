.mapboxgl-map {
	@apply font-normal;
}
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
	@apply mr-2;
	@apply mb-2;
}
.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
	@apply ml-2;
	@apply mb-2;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
	@apply m-0;
}

.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-zoom-out,
.mapboxgl-ctrl-scale {
	@apply hidden !important;
	@apply lg:block !important;
}

a.mapboxgl-ctrl-logo {
	background-size: auto 16px;
	background-position: bottom left;
}
