.ant-modal-centered .ant-modal:not(.modal-2) {
	min-width: 320px;
	.ant-modal-content {
		@apply overflow-hidden rounded-md;
	}

	.ant-modal-confirm-body {
		@apply flex;
		@apply flex-col;
		@apply items-center;
		& > .anticon {
			@apply mr-0;
		}
		.ant-modal-confirm-title {
			@apply font-bold;
			@apply mt-4;
		}

		.ant-modal-confirm-content {
			@apply w-full;
			@apply text-center;
			@apply text-sm;
		}
	}

	.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
		@apply ml-0;
	}
	.ant-modal-confirm-btns {
		@apply flex;
		@apply justify-center;
		.ant-btn {
			@apply rounded-md;
			min-width: 120px;
		}
	}
}

.modal-2 {
	button.btn-delete {
		&,
		&:hover,
		&:focus {
			@apply border-error bg-error text-background-primary;
		}
		&:hover {
			@apply border-error bg-error;
		}
	}
	button.btn-cancel {
		&,
		&:hover,
		&:focus {
			@apply border-background-secondary bg-background-secondary text-text-secondary;
		}
		&:hover {
			@apply border-background-secondary bg-background-secondary;
		}
	}
}
