.ant-upload.ant-upload-drag {
	@apply rounded-xl;
	background: inherit;
}

.ant-upload-list-picture .ant-upload-list-item {
	@apply rounded-xl;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
	@apply flex items-center justify-center;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	@apply h-auto max-h-full w-auto max-w-full;
}
