//#region Table

.ant-table {
	@apply h-0 flex-auto overflow-hidden rounded-xl border-background-primary bg-white;

	table {
		@apply border-collapse;
	}

	.ant-table-tbody > tr > td {
		@apply border-none;
	}

	.ant-table-row:not(:hover) {
		&:nth-child(odd) {
			td {
				background-color: #edf2f7;
			}

			.ant-table-cell-fix-left {
				background-color: #edf2f7;
			}
			.ant-table-cell-fix-right {
				background-color: #edf2f7;
			}
		}
		&:nth-child(even) {
			td {
				@apply bg-white;
			}
			.ant-table-cell-fix-left {
				@apply bg-white;
			}
			.ant-table-cell-fix-right {
				@apply bg-white;
			}
		}
	}
	td.ant-table-cell-fix-right {
		@apply -right-6 !important;
	}
	th.ant-table-cell-fix-right {
		@apply -right-4 !important;
	}
	.ant-table-thead > tr > th {
		@apply border-b-0 bg-transparent font-semibold text-white;
	}

	.ant-table-thead > tr > th {
		@apply bg-secondary;

		&:hover,
		&.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
			background-color: #009073;
		}
	}

	.ant-table-header,
	.ant-table-thead {
		@apply bg-secondary px-4;
	}
	.ant-table-body {
		@apply px-4;
	}

	td.ant-table-column-sort {
		@apply bg-transparent;
	}
}

//#endregion

.ant-table-wrapper {
	.ant-spin-nested-loading {
		@apply h-full;
		& > div > .ant-spin {
		}
	}
	.ant-spin-container {
		@apply flex h-full flex-col;
	}
}

.ant-pagination.ant-table-pagination {
	@apply flex;
	@apply items-center;
	@apply justify-start;

	.ant-pagination-total-text {
		@apply flex-1;
	}
	.ant-pagination-item:hover,
	.ant-pagination-item-active {
		@apply border-secondary;
		a {
			@apply text-secondary;
		}
	}
	.ant-pagination-prev:hover .ant-pagination-item-link,
	.ant-pagination-next:hover .ant-pagination-item-link {
		@apply border-secondary;
		@apply text-secondary;
	}
}

.ant-table-column-sorter-inner {
	.ant-table-column-sorter-up {
		@apply text-white;
	}

	.ant-table-column-sorter-down {
		@apply text-white;
	}

	& > .active {
		@apply text-primary;
	}
}

.ant-table-column-title {
	@apply text-white;
}
