.mapboxgl-popup {
	@apply z-10 font-normal;
}

.mapboxgl-popup-close-button {
	@apply p-4 py-3 text-base;
}

.mapboxgl-popup-content {
	@apply overflow-hidden rounded-xl p-0 outline-border-default;
}
