a.ant-btn {
	@apply py-0 !important;
	@apply flex items-center justify-center;
}

.ant-btn-round.ant-btn-text.ant-btn {
	@apply h-auto rounded-full;
}
.ant-btn {
	@apply h-10 rounded-md py-0;
}

.ant-btn-primary {
	@apply border-secondary bg-secondary;
	&:hover,
	&:focus {
		@apply border-primary bg-primary;
	}
}

.ant-btn-default {
	&,
	&:hover,
	&:focus {
		@apply text-primary;
		@apply bg-background-primary;
		@apply border-primary;
	}

	&:hover {
		@apply text-secondary;
		@apply border-secondary;
	}
}

button.ant-btn-primary {
	&,
	&:hover,
	&:focus {
		@apply border-secondary bg-secondary text-background-primary;
	}
	&:hover {
		@apply border-primary bg-primary;
	}
}

.ant-btn-ghost {
	@apply rounded-md;
	@apply h-auto;
	min-height: 40px;

	&:hover,
	&:focus {
		@apply text-secondary;
		@apply border-secondary;
	}
}
