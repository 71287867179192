.ant-descriptions-header {
	@apply mb-4;
}
.ant-descriptions {
	@apply mb-4;
}

.ant-descriptions-title {
	@apply font-normal;
	@apply text-text-secondary;
	@apply opacity-60;
	@apply text-sm;
}
.ant-descriptions-item-label,
.ant-descriptions-item-content {
	// @apply text-sm;
}

.ant-descriptions-item-label {
	@apply font-semibold;
}

.ant-descriptions-item-container {
	.ant-descriptions-item-label {
		@apply font-semibold;
		@apply w-32;
	}
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
	@apply pb-2;
}
